import axios, { AxiosError, AxiosResponse } from 'axios';
import i18n from 'i18next';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  clearAccessToken,
  getAccessToken,
  getLanguage,
  setAccessToken,
  setLanguage as setBrowseLanguage,
} from 'service/browserStorageServices';
import { getCurrentUser, retrieveAccessToken } from 'service/userServices';
import { ClinicDto, UserDto } from 'ts-types/api.types';

type LoginFunction = (username: string, password: string, verificationCode?: string) => Promise<any>;
//TODO check
interface State {
  isAuthenticated: boolean;
  skip2faInit: boolean;
  setSkip2faInit: (skip: boolean) => void;
  currentUser?: UserDto;
  currentClinic?: ClinicDto;
  login: LoginFunction;
  logout: () => void;
  reloadCurrentUser: () => Promise<void | any>;
  fetchingUser: boolean;
  fetchingMessages: boolean;
  setFetchingMessages: (fetchingMessages: boolean) => void;
  sessionExpiredModalOpen: boolean;
  onSessionExpiredModalClose: () => void;
  setSessionExpiredModalOpen: (sessionExpiredModalOpen: boolean) => void;
  confServerNotRespondingModalOpen: boolean;
  onConfServerNotRespondingModalClose: () => void;
  setConfServerNotRespondingModalOpen: (sessionExpiredModalOpen: boolean) => void;
  language: string;
  updateLanguage: (lang: string) => void;
}

const AuthContext = React.createContext<State>({
  isAuthenticated: false,
  skip2faInit: false,
  setSkip2faInit: (skip: boolean) => {},
  login: (username: string, password: string) => {return {} as Promise<any>;},
  logout: () => {},
  currentUser: undefined,
  currentClinic: undefined,
  reloadCurrentUser: () => {return {} as Promise<void | UserDto>;},
  fetchingUser: true,
  fetchingMessages: true,
  setFetchingMessages: (fetchingMessages: boolean) => {},
  sessionExpiredModalOpen: false,
  onSessionExpiredModalClose: () => {},
  setSessionExpiredModalOpen: (sessionExpiredModalOpen: boolean) => {},
  confServerNotRespondingModalOpen: false,
  onConfServerNotRespondingModalClose: () => {},
  setConfServerNotRespondingModalOpen: (confServerNotRespondingModalOpen: boolean) => {},
  language: getLanguage(),
  updateLanguage: (lang: string) => {},
});

interface Props extends JSX.ElementChildrenAttribute {}

const AuthProvider = (props: Props) => {

  const accessToken = React.useMemo(() => getAccessToken(), []);

  const { push, location } = useHistory();

  const publicRoutes = ['/login', '/forget-password', '/reset-password', '/registration', '/verification-code'];

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!accessToken);
  const [skip2faInit, setSkip2faInit] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<UserDto | undefined>(undefined);
  const [currentClinic, setCurrentClinic] = useState<ClinicDto | undefined>(undefined);
  const [fetchingUser, setFetchingUser] = useState<boolean>(!!accessToken);
  const [fetchingMessages, setFetchingMessages] = useState<boolean>(true);
  const [sessionExpiredModalOpen, setSessionExpiredModalOpen] = useState<boolean>(false);
  const [confServerNotRespondingModalOpen, setConfServerNotRespondingModalOpen] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>(getLanguage());

  const isPathExcludedFromSessionModal = (path: string): boolean => {

    const excludedPaths = ['auth', 'password-reset', 'public'];
    let excluded = false;

    for (const excludedPath of excludedPaths) {
      if (path.includes(excludedPath)) {
        excluded = true;
        break;
      }
    }

    return excluded;
  };

  const sessionExpiredHandler = (error: AxiosError) => {

    const { response } = error;

    if (response && response.status === 401) {
      if (!sessionExpiredModalOpen && !isPathExcludedFromSessionModal(response.data.path)) {
        setSessionExpiredModalOpen(true);
      }
    } else if (response && response.status === 503){
      setConfServerNotRespondingModalOpen(true)
    }

    return Promise.reject(error);
  };

  axios.interceptors.response.use(response => response, sessionExpiredHandler);

  const updateLanguage = useCallback((lang: string) => {
    setLanguage(lang);
    setBrowseLanguage(lang);
    i18n.changeLanguage(lang);
    moment.locale(lang);
  }, [setLanguage]);

  const logout = React.useCallback(() => {
    clearAccessToken();
    setIsAuthenticated(false);
    setSessionExpiredModalOpen(false);
    setCurrentUser(undefined);
  }, [setIsAuthenticated]);


  const onSessionExpiredModalClose = useCallback(() => {
    setSessionExpiredModalOpen(false);
  }, [setSessionExpiredModalOpen]);

  const onConfServerNotRespondingModalClose = useCallback(() => {
    setConfServerNotRespondingModalOpen(false);
  }, [setConfServerNotRespondingModalOpen]);

  const reloadCurrentUser = useCallback(() => {
    setFetchingUser(true);
    return getCurrentUser()
    .then(response => {
      setCurrentUser(response);
      setIsAuthenticated(true);
      updateLanguage(response.language);
    })
    .catch(logout)
    .finally(() => setFetchingUser(false));
  }, [setFetchingUser, setCurrentUser, updateLanguage, logout]);

  const login: LoginFunction = React.useCallback(async (
    username: string,
    password: string,
    verificationCode?: string) => {

    return await retrieveAccessToken(username, password, verificationCode)
    .then((response: AxiosResponse) => {
      const accessToken = response.headers['authorization'].replace('Bearer ', '');
      setAccessToken(accessToken);

      return reloadCurrentUser();
    });
  }, [reloadCurrentUser]);

  React.useEffect(() => {
    if (accessToken) {
      reloadCurrentUser();
    }
  }, []);

  React.useEffect(() => {
    if (!isAuthenticated && !publicRoutes.includes(location.pathname)) {
      push('/login');
    }
  }, [isAuthenticated, push]);

  const state: State = React.useMemo(() => {
    return {
      isAuthenticated, setIsAuthenticated,
      skip2faInit, setSkip2faInit,
      currentUser, setCurrentUser,
      currentClinic, setCurrentClinic,
      fetchingUser, setFetchingUser,
      fetchingMessages, setFetchingMessages,
      sessionExpiredModalOpen, setSessionExpiredModalOpen,
      onSessionExpiredModalClose,
      confServerNotRespondingModalOpen, setConfServerNotRespondingModalOpen,
      onConfServerNotRespondingModalClose,
      language, updateLanguage,
      login, logout,
      reloadCurrentUser,
    };
  }, [isAuthenticated, setIsAuthenticated,
    skip2faInit, setSkip2faInit,
    currentUser, setCurrentUser,
    currentClinic, setCurrentClinic,
    fetchingUser, setFetchingUser,
    fetchingMessages, setFetchingMessages,
    sessionExpiredModalOpen, setSessionExpiredModalOpen,
    onSessionExpiredModalClose,
    confServerNotRespondingModalOpen, setConfServerNotRespondingModalOpen,
    onConfServerNotRespondingModalClose,
    language, updateLanguage,
    login, logout,
    reloadCurrentUser,
  ]);

  return (
    <AuthContext.Provider value={state}>
      {props.children}
    </AuthContext.Provider>

  );
};

export const useAuthContext = () => React.useContext(AuthContext);

export default AuthProvider;