import axios from 'axios';
import CompositeButton from 'components/final-form/CompositeButton';
import InnerTableActionButton from 'components/InnerTableActionButton';
import LoaderComponent from 'components/LoaderComponent';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Modal, Table } from 'semantic-ui-react';
import { getAllExerciseTemplates } from 'service/teresaConfService';
import styled from 'styled-components';
import { ExerciseTemplateDto } from 'ts-types/api.types';
import { emptyTableRows } from 'util/tableUtils';


const ExerciseTemplateModalContainer = styled.div`
  padding: 1.75rem 2rem;
  background-color: var(--very-very-light-blue);
  font-family: 'Manrope', sans-serif;

  .form-grid {

    .label {
      display: inline-block;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    
  }

  .actions-row {

    text-align: right;

    .success {
      color: darkgreen;
      display: inline-block;
      margin-left: 0.5rem;
    }

    .ui.button + .ui.button {
      margin-left: 1rem;
    }

    .ui.primary.button {
      background-color: var(--primary-color);

      :hover {
        background-color: transparent;
        border-color: var(--primary-color);
        color: var(--primary-color);
      }

      :focus {
        background-color: transparent;
        border-color: var(--primary-color);
        color: var(--primary-color);
      }
    }

    .ui.secondary.button {
      background-color: var(--secondary-color);

      :hover {
        background-color: transparent;
        border-color: var(--secondary-color);
        color: var(--secondary-color);
      }
    }

    .ui.large.button {
      padding: 0.6rem 3rem 0.6rem;
      font-weight: 500;
      font-family: 'Manrope', sans-serif;
    }

  }
`;

const TableContainer = styled.div`
  flex-grow: 1;
  cursor: context-menu;
  max-height: 300px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  overflow: auto;
  border-radius: unset;
  border: 1px solid var(--light-gray) !important;
      
  & .ui.table tr {
    height: 35px;
    border: none;
  }
  
  .ui.table tr td {
    border-top: none;
  }
  
  .no-padding {
    border: 1px solid var(--light-gray)!important;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 1rem;
  }
  
 .ui.basic.striped.table tbody tr:nth-child(2n) {
    background-color: var(--very-light-gray) !important;
 }

`;

interface Props {
  clinicId: number,
  doctorId?: number,
  patientId?: number,
  exerciseModalOpen: boolean;
  prevPath: string;
  onCloseExerciseModal: () => void;
}

const cancelTokenSource = axios.CancelToken.source();

const ExerciseTemplateModal = (props: Props) => {

  const { t } = useTranslation('teresa');
  const history = useHistory();
  const { state } = useLocation();

  const { patientId, exerciseModalOpen, onCloseExerciseModal, doctorId, prevPath } = props;

  const [exerciseTemplates, setExerciseTemplates] = useState<ExerciseTemplateDto[]>([]);
  const [disableSubmitting, setDisableSubmitting] =
    useState<boolean>(false);

  useEffect(() => {
    fetchExerciseTemplates();
  }, []);

  const fetchExerciseTemplates = () => {
    getAllExerciseTemplates(cancelTokenSource)
    .then(response => {
      setExerciseTemplates(response);
    });

  };

  const goToEditExerciseSet = () =>  {
    setDisableSubmitting(true);
    setTimeout(() => {
      history.push("/exercise-testing", {
        patientId: patientId,
        prevPath: prevPath,
        exerciseTemplateId: undefined,
        clinicId: state.clinicId
      })
    }, 1200);
  };

  const renderForm = (): React.ReactNode => {

    return (
      <div>
        <div className='form-grid'>
          {disableSubmitting &&
            <LoaderComponent message={t('templates.loading.message')} />
          }
          <TableContainer>
            <Table basic='very' striped size='small'>
              <Table.Body className='table-body'>
                {exerciseTemplates.map((exerciseTemplates) => {
                  return <Table.Row key={exerciseTemplates.id}>
                    <Table.Cell width={12}>
                      {exerciseTemplates.description}
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <InnerTableActionButton
                        message={t('button.select')}
                        onConfirm={() => {
                          setDisableSubmitting(true);
                          history.push('/exercise-testing',
                            { exerciseTemplateId: exerciseTemplates.id, patientId: patientId, prevPath: props.prevPath,
                              doctorId: doctorId, clinicId: state.clinicId })
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>;
                })}
                {emptyTableRows(exerciseTemplates.length, 2)}
              </Table.Body>
            </Table>
          </TableContainer>

          <div className='actions-row'>
            <CompositeButton
              type='button'
              className='action-button'
              style={{ whiteSpace: 'nowrap', display: 'inline-block' }}
              primary
              onClick={() => goToEditExerciseSet()}
              disabled={disableSubmitting}
            >
              {t('button.withoutTemplate')}
            </CompositeButton>
            <CompositeButton
              type='button'
              className='action-button'
              style={{ whiteSpace: 'nowrap', display: 'inline-block' }}
              secondary
              floated='right'
              onClick={onCloseExerciseModal}
            >
              {t('action.cancel')}
            </CompositeButton>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={exerciseModalOpen}
      onClose={onCloseExerciseModal}
      style={{ width: '40rem', borderRadius: '5px', backgroundColor: 'var(--very-very-light-blue)', fontFamily: '\'Manrope\' sans-serif' }}
      closeOnDimmerClick={false}
    >
      <Modal.Header style={{ backgroundColor: 'var(--very-very-light-blue)', fontFamily: '\'Manrope\' sans-serif' }}>
        {t('exerciseTemplate.viewTitle')}
      </Modal.Header>
      <ExerciseTemplateModalContainer>
        {renderForm()}
      </ExerciseTemplateModalContainer>
    </Modal>
  );
};

export default ExerciseTemplateModal;