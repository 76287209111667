import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import DataLabel from 'components/final-form/DataLabel';
import Input from 'components/final-form/Input';
import PatientInfoHeaderComponent from 'components/final-form/PatientInfoHeaderComponent';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import CompositeButton from 'components/final-form/CompositeButton';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import UpdateTestingConfirmation from 'components/UpdateTestingConfirmation';
import UpsertContentWrapperDiv from 'components/UpsertContentWrapperDiv';
import { useClinicHeaderContext } from 'context/ClinicHeaderContext';
import createDecorator from 'final-form-focus';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import TemplateExerciseConfSegment from 'routes/exercise-template/TemplateExerciseConfSegment';
import PatientTestingQuestionSetSegment from 'routes/patienttesting/PatientTestingQuestionSetSegment';
import { Grid } from 'semantic-ui-react';
import {
  getExerciseConfsForPatientExercise, getExerciseInTemplates,
  getExerciseTemplate,
  searchQuestionSet,
} from 'service/teresaConfService';
import {
  addPatientExercising, editPatientExercising,
  getPatientExercising,
} from 'service/patientExercisingService';
import { getPatientDetails } from 'service/patientService';
import styled from 'styled-components';
import { ScopeType } from 'ts-types/api.enums';
import {
  TeresaConfQuestionSetSearchRequest,
  DomainDto, ExerciseConfDto, ExerciseTemplateDto, PatientDto,
  QuestionSetDto,
  UpsertExerciseTemplateDto, UpsertPatientExercisingDto,
} from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { getMinMaxDescriptionTitle } from 'util/stringUtils';
import { composeValidators, mustBeNumber, required, sessionValidation } from 'util/validatorUtils';

const StyledLeftDataDiv = styled.div`
  font-weight: 600;
  min-height: 35px;
  line-height: 35px;
  background-color: var(--very-light-gray);
  border-radius: 4px;
`;

const StyledRightDataDiv = styled.div`
  font-weight: 600;
  min-height: 35px;
  line-height: 35px;
  background-color: var(--very-light-gray);
  border-radius: 4px;
`;

const ScrollableTsaGrid = styled(TsaGrid)`
  position: fixed;
  overflow: auto;
  top: 110px;
  bottom: 75px;
  width: calc(100% - 255px);
`;

const FormButtonsWrapper = styled(TsaGrid)`
  position: fixed;
  bottom: 0;
  width: calc(100% - 255px);
  height: 75px;

  & .buttons-container {
    padding-top: 20px;
    border-top: 1px solid var(--very-light-blue);
  }
`;

interface Props {

}
//TODO check
interface UpsertExerciseTemplateForm extends UpsertExerciseTemplateDto, DomainDto, UpsertPatientExercisingDto {
}

const cancelTokenSource = axios.CancelToken.source();

const UpsertPatientExercisingForm = (props: Props) => {

  const {setClinicId} = useClinicHeaderContext();

  const { t } = useTranslation('teresa');
  const { language, currentUser } = useAuthContext();
  const { state } = useLocation();
  const focusOnErrors = createDecorator();
  const history = useHistory();
  const patientExercisingId: number | undefined = state?.patientExercisingId ? Number(state?.patientExercisingId) : undefined;
  const exerciseProgress: string | undefined = state.exerciseProgress ? state.exerciseProgress : undefined;
  const patientId: number | undefined = state?.patientId ? Number(state?.patientId) : undefined;
  const exerciseTemplateId: number | undefined = state.patientId ? Number(state?.exerciseTemplateId) : undefined;
  const prevPath: string | undefined = state.prevPath ? state?.prevPath : undefined;
  const fromPatientExercising: boolean = state.fromPatientExercising !== undefined;
  const isIpad = useIsIpadWidthOrBelow();

  const [exerciseTemplate, setExerciseTemplate] = useState<UpsertExerciseTemplateDto | undefined>(undefined);
  const [markedExerciseConf, setMarkedExerciseConf] = useState<ExerciseConfDto | undefined>(undefined);
  const [exerciseInTemplatesList, setExerciseInTemplatesList] = useState<string[]>([]);
  const [selectedExerciseConfList, setSelectedExerciseConfList] = useState<ExerciseConfDto[]>([]);
  const [availableExerciseConfList, setAvailableExerciseConfList] = useState<ExerciseConfDto[]>([]);
  const [selectedQuestionSets, setSelectedQuestionSets] = useState<number[]>([]);
  const [availableQuestionSets, setAvailableQuestionSets] = useState<number[]>([]);
  const [patientExercising, setPatientExercising] = useState<UpsertPatientExercisingDto>();
  const [patient, setPatient] = useState<PatientDto>();
  const [questionSets, setQuestionSets] = useState<QuestionSetDto[]>([]);
  const [confirmationPopupMsg, setConfirmationPopupMsg] = useState<string | undefined>(undefined);
  const [numOfCompletedExercises, setNumOfCompletedExercises] = useState<number | undefined>(undefined);
  const [numOfSessions, setNumberOfSessions] = useState<number | undefined>(undefined);
  const [firstIncompleteQuestionSetId, setFirstIncompleteQuestionSetId] = useState<number>();
  const [successMsg, setSuccessMsg] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [formDataLoaded, setFormDataLoaded] = useState<boolean>(true);

  const goToPreviousPage = () => {
    history.goBack();
  };

  useEffect(() => {
    setClinicId(state?.clinicId ? Number(state.clinicId) : undefined)
    fetchData(true);
  }, []);

  useEffect(() => {
    if (markedExerciseConf) {
      getExerciseInTemplates(markedExerciseConf.id, cancelTokenSource)
      .then(list => {
        const exerciseInTemplatesStringList: string[] = list
        .map(
          (template, index) =>
            index === 0 ? translateDescription(template) : ', ' + translateDescription(template)
        );
        setExerciseInTemplatesList(exerciseInTemplatesStringList);
      });
    }
  }, [language]);

  useEffect(() => {
    let firstIncompleteQuestionSetId: number | undefined = undefined;
    if (patientExercising && patientExercising.questionSetDtos) {
      selectedQuestionSets.forEach(questionSetId => {
        let patientQuestionSetDto =
          patientExercising.questionSetDtos.find(pt => pt.questionSetId === questionSetId);
        if (!firstIncompleteQuestionSetId && (!patientQuestionSetDto || !patientQuestionSetDto.completed)) {
          firstIncompleteQuestionSetId = questionSetId;
        }
      });
    }

    setFirstIncompleteQuestionSetId(firstIncompleteQuestionSetId);

  }, [selectedQuestionSets, patientExercising]);

  const fetchData = async (active: boolean) => {
    setFormDataLoaded(false);

    try {

      const patientData = await getPatientDetails(patientId!, cancelTokenSource);
      setPatient(patientData);

      const exerciseConfsResponse = await getExerciseConfsForPatientExercise(
        patientData.clinicId, patientExercisingId, cancelTokenSource);

      const exerciseConfIds = exerciseConfsResponse.map(e => e.id);

      let questionSetSearchValues: Partial<TeresaConfQuestionSetSearchRequest> = {
        clinicId: patientData.clinicId,
        patientExercisingId: patientExercisingId,
        searchKey: '',
        active: true,
        scopeType: ScopeType.REHAB,
        orderByName: true
      };

      questionSetSearchValues.scopeType = ScopeType.REHAB

      const questionSetsResponse =
        await searchQuestionSet(questionSetSearchValues, cancelTokenSource);
      setQuestionSets(questionSetsResponse);
      const availableQuestionsSetIds = _.map(questionSetsResponse, 'id');
      setAvailableQuestionSets(availableQuestionsSetIds);

      if (patientExercisingId) {
        const patientExercisingResponse =
          await getPatientExercising(patientExercisingId, !fromPatientExercising, cancelTokenSource);
        setPatientExercising(patientExercisingResponse);
        setNumOfCompletedExercises(patientExercisingResponse.completedSessions);
        setNumberOfSessions(patientExercisingResponse.numberOfSessions);

        const questionSetIds: number[] = patientExercisingResponse.questionSetIds;
        setSelectedQuestionSets(questionSetIds);
        setAvailableQuestionSets(availableQuestionsSetIds.filter(qsId => !questionSetIds.includes(qsId)));


        const exerciseConfigIds = patientExercisingResponse.exerciseConfDtoList.map(e => e.id);
        let newList = patientExercisingResponse.exerciseConfDtoList;
        newList = _.orderBy(newList, ['orderIndex'], ['asc']);

        setSelectedExerciseConfList(newList);
        setAvailableExerciseConfList(exerciseConfsResponse.filter(ec => !exerciseConfigIds.includes(ec.id)));
      } else if (exerciseTemplateId) {
        const exerciseTemplate = await getExerciseTemplate(exerciseTemplateId, cancelTokenSource);
        setExerciseTemplate(exerciseTemplate);
        if (exerciseTemplate) {
          const exerciseConfigIds = exerciseTemplate.exerciseConfList.map(e => e.id);
          let newList = exerciseTemplate.exerciseConfList
          .filter(e => exerciseConfIds.includes(e.id));

          newList = _.orderBy(newList, ['orderIndex'], ['asc']);

          setSelectedExerciseConfList(newList);
          setAvailableExerciseConfList(exerciseConfsResponse.filter(ec => !exerciseConfigIds.includes(ec.id)));
        }
      } else {
        setSelectedExerciseConfList([]);
        setAvailableExerciseConfList(exerciseConfsResponse);
      }
    } catch (e) {
      handleError(e.response.data);
    } finally {
      setFormDataLoaded(true);
    }
  };

  const handleError = useCallback((error: any) => {

    if (error) {
      let errMsg = undefined;
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.invalidEmail,
        errorUtils.exerciseTemplateNotFound,
        errorUtils.patientExerciseConfListEmpty,
        errorUtils.sessionNumberMustBeGreaterThanCompletedSessions
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errMsg = t(`error.${violation.errorCode}`);
          }
        });
      }

      if (!errMsg) {
        if (knownErrors.includes(errorCode)) {
          errMsg = t(`error.${errorCode}`);
        } else {
          errMsg = t('error.general');
        }
      }

      setErrorMessage(errMsg);
    }

  }, []);

  const submitHandler = async (values: Partial<UpsertExerciseTemplateForm>) => {

    const exerciseTemplateIdOrNull = exerciseTemplateId === 0 ? null : exerciseTemplateId;

    const request: Partial<UpsertPatientExercisingDto> = {
      exerciseTemplateId: exerciseTemplateIdOrNull as number | undefined,
      doctorId: currentUser?.id,
      patientId: patientId,
      questionSetIds: selectedQuestionSets,
      creationDate: patientExercising?.creationDate,
      status: patientExercising?.status,
      exerciseConfDtoList: selectedExerciseConfList,
      patientExercises: patientExercising?.patientExercises,
      numberOfSessions: values.numberOfSessions,
    };

    const onSave = (exercisingId?: number, patientExercisingQuestionSetId?: number) => {
      patientExercisingId
        ? setSuccessMsg(t('exerciseTesting.updateSuccess'))
        : setSuccessMsg(t('exerciseTesting.addSuccess'));

        setTimeout(() => {
          if (exercisingId) {
            history.push('/exercising', {
              patientExercisingId: exercisingId,
              id: patientId,
              patientExercisingQuestionSetId: patientExercisingQuestionSetId,
              prevPath: prevPath!,
              clinicId: state.clinicId
            });
          } else {
            prevPath === '/'
              ? history.push(prevPath)
              : history.push(prevPath!, { id: patientId, clinicId: state.clinicId });
          }
        }, 1200);
    };

    try {
      if (patientExercisingId) {
        const patientExercisingDto = await editPatientExercising(patientExercisingId, request, cancelTokenSource);
        onSave(patientExercisingId, patientExercisingDto.patientExercisingQuestionSetId);
      } else {
        const addExercisingResponse = await addPatientExercising(request, cancelTokenSource);
        onSave(addExercisingResponse.id, addExercisingResponse.patientExercisingQuestionSetId);
      }
    } catch (e) {
      handleError(e.response.data);
    }
  };

  const setData = (selectedExerciseConfs: Array<ExerciseConfDto>, availableExerciseConfs: Array<ExerciseConfDto>) => {

    const isAlreadyCompleted = patientExercising &&
      patientExercising.exerciseConfDtoList.length < selectedExerciseConfs.length;

    const changeStatusToCompleted = patientExercising &&
      numOfCompletedExercises === selectedExerciseConfs.length;

    if (isAlreadyCompleted) {
      setConfirmationPopupMsg(t('exerciseTesting.changeStatusToRunning'));
    } else if (changeStatusToCompleted) {
      setConfirmationPopupMsg(t('exerciseTesting.changeStatusToCompleted'));
    } else if (confirmationPopupMsg) {
      setConfirmationPopupMsg(undefined);
    }

    setSelectedExerciseConfList(selectedExerciseConfs);
    setAvailableExerciseConfList(availableExerciseConfs);
  };

  const setQuestionSetData = (selected: number[], available: number[]) => {
    setSelectedQuestionSets(selected);
    setAvailableQuestionSets(available);
  };

  const translateDescription = (exercise: ExerciseTemplateDto) => {
    const description = exercise.description;
    const questionSetNames: { [key: string]: string } = {
      'de': exercise.description || description,
      'en': exercise.descriptionEn || description,
      'fr': exercise.descriptionFr || description,
      'it': exercise.descriptionIt || description,
    };
    return questionSetNames[language];
  };

  const setSelectedExerciseInTemplates = async (exercise: ExerciseConfDto) => {
    setMarkedExerciseConf(exercise);
    try {
      const list = await getExerciseInTemplates(exercise.id, cancelTokenSource);
      let exerciseInTemplatesStringList: string[] = [];
      list.forEach(template => {
        exerciseInTemplatesStringList.push(list.indexOf(template) === 0 ? translateDescription(template) : ', ' + translateDescription(template));
      });
      setExerciseInTemplatesList(exerciseInTemplatesStringList);
    } catch (e) {
      handleError(e.response.data);
    }
  };

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={(values: Partial<UpsertExerciseTemplateForm>) => submitHandler(values)}
        // @ts-ignore
        decorators={[focusOnErrors]}
        initialValues={{
          questionSetIds: patientExercising?.questionSetIds,
          exerciseConfList: exerciseTemplate?.exerciseConfList,
          numberOfSessions: patientExercising?.numberOfSessions,
        }}
        subscription={{ pristine: true, submitting: true }}
        render={(formProps: FormRenderProps<UpsertExerciseTemplateForm>) => renderExerciseTemplateForm(formProps)}
      />
    );
  };

  const renderExerciseTemplateForm = ({
                                        handleSubmit,
                                        submitting,
                                        form,
                                      }: FormRenderProps<UpsertExerciseTemplateForm>): React.ReactNode => {

    const minMaxDescriptionTitle = getMinMaxDescriptionTitle(numOfCompletedExercises, null,t);

    const selectedExerciseInTemplatesLeft = markedExerciseConf && selectedExerciseConfList.includes(markedExerciseConf)
      ? exerciseInTemplatesList
      : [];
    const selectedExerciseInTemplatesRight = markedExerciseConf && availableExerciseConfList.includes(markedExerciseConf)
      ? exerciseInTemplatesList
      : [];

    const numberOfSessionsLabel = patientExercisingId
        ? t('exerciseTesting.numberOfSessionsAndNumberOfCompletedSessions',
            { completedSessions: numOfCompletedExercises })
        : t('exerciseTesting.numberOfSessions')

    return (
      <form onSubmit={handleSubmit}>
        <TsaGrid>
          <PatientInfoHeaderComponent
            titleElement={
              <div className='title-h1' style={{marginBottom: 0}}>
                {t('exerciseSet.title')}
              </div>
            }
            patient={patient}
            patientExercising={patientExercising}
          />
        </TsaGrid>
        <ScrollableTsaGrid className='form-class' style={isIpad ? { top: '170px', width: 'calc(100% - 40px' } : {}}>
          {errorMessage &&
            <Grid.Row>
              <Grid.Column width={15}>
                <div className='error'>
                  <StyledErrorMessage onDismiss={() => setErrorMessage(undefined)}>
                    <div>{errorMessage}</div>
                  </StyledErrorMessage>
                </div>
              </Grid.Column>
            </Grid.Row>
          }
          <Grid.Row>
            <Grid.Column width={7}>
              <DataLabel>{numberOfSessionsLabel}</DataLabel>
              <Field
                name='numberOfSessions'
                component={Input}
                title={minMaxDescriptionTitle}
                validate={composeValidators(mustBeNumber, required, sessionValidation(numOfCompletedExercises))}
                fluid
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={7} verticalAlign={'middle'}>
              <DataLabel>{t('questionSet.title')}</DataLabel>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <PatientTestingQuestionSetSegment
              questionSetMap={_.keyBy(questionSets, 'id')}
              selectedQuestionSets={selectedQuestionSets}
              availableQuestionSets={availableQuestionSets}
              setData={setQuestionSetData}
              patientQuestionSets={patientExercising?.questionSetDtos}
            />
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={6} verticalAlign='middle'>
              <DataLabel>{t('exerciseTemplate.exerciseSelection')}</DataLabel>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <TemplateExerciseConfSegment
              selectedExerciseConfList={selectedExerciseConfList && selectedExerciseConfList.length > 0 ? selectedExerciseConfList : []}
              availableExerciseConfList={availableExerciseConfList}
              setData={setData}
              setSelectedExerciseInTemplates={setSelectedExerciseInTemplates}
              patientExercises={patientExercising?.patientExercises}
            />
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={6} verticalAlign={'middle'}>
              <DataLabel>{t('exerciseTemplate.selectedTestsInTemplate')}</DataLabel>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={7}>
              <StyledLeftDataDiv>{selectedExerciseInTemplatesLeft}</StyledLeftDataDiv>
            </Grid.Column>
            <Grid.Column width={1} />
            <Grid.Column width={7}>
              <StyledRightDataDiv>{selectedExerciseInTemplatesRight}</StyledRightDataDiv>
            </Grid.Column>
          </Grid.Row>
        </ScrollableTsaGrid>
        <FormButtonsWrapper style={isIpad ? {width: 'calc(100% - 40px)'} : {}}>
          <Grid.Row textAlign='right'>
            <Grid.Column width={15}>
              <div className='buttons-container'>
                <CompositeButton
                  secondary
                  type='button'
                  floated='right'
                  className='action-button'
                  style={{ display: 'inline-block' }}
                  disabled={submitting || !!successMsg}
                  onClick={goToPreviousPage}
                >
                  {t('action.cancel')}
                </CompositeButton>

                {confirmationPopupMsg
                  ? <UpdateTestingConfirmation disabled={submitting || !!successMsg}
                                               updateConfirmationText={confirmationPopupMsg}
                                               submit={() => submitHandler(form.getState().values)} />
                  : <CompositeButton
                    primary
                    type='submit'
                    floated='right'
                    className='action-button'
                    style={{ display: 'inline-block', marginRight: '2rem' }}
                    disabled={submitting || !!successMsg}
                  >
                    {t('button.save')}
                  </CompositeButton>
                }

                {
                  successMsg &&
                  <SaveAndUpdateConfirmationMessage>
                    {successMsg}
                  </SaveAndUpdateConfirmationMessage>
                }
              </div>
            </Grid.Column>
          </Grid.Row>
        </FormButtonsWrapper>
      </form>
    );
  };

  return (
    <UpsertContentWrapperDiv>
      {formDataLoaded
        ? <React.Fragment>
          {renderFinalForm()}
        </React.Fragment>
        : <LoaderComponent message={t('exerciseTemplate.loading')} />
      }
    </UpsertContentWrapperDiv>
  );
};

export default UpsertPatientExercisingForm;