import axios, { CancelTokenSource } from 'axios';
import { backendUrls } from 'service/http';
import {
  AccountDto,
  AccountSearchRequest,
  TriggerAccountActivationRequest,
  UpsertAccountDto,
  UpsertDoctorDto,
} from 'ts-types/api.types';

const apiUri = `${backendUrls.apiRoot}/account`;
//TODO check
export const getAdmins = (source: CancelTokenSource): Promise<AccountDto[]> => {
   return axios
   .get(apiUri, { cancelToken: source.token })
   .then(response => response.data);
 };

export const getAccountById = (accountId: number, source: CancelTokenSource): Promise<UpsertAccountDto> => {
  return axios
  .get(`${apiUri}/${accountId}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const activateAdmin = (admin: Partial<UpsertDoctorDto>) => {
  return axios
  .post(`${apiUri}/activate`, admin)
  .then(response => response.data);
};

export const deactivateAdmin = (id: number) => {
  return axios
  .post(`${apiUri}/${id}/deactivate`)
  .then(response => response.data);
};

export const searchAccounts = (
  request: Partial<AccountSearchRequest>,
  source: CancelTokenSource): Promise<AccountDto[]> => {
  return axios
  .post(`${apiUri}/search`, request, { cancelToken: source.token })
  .then(response => response.data);
};

// export const resendInvitation = (id: number, request: Partial<AdminActivationRequest>) => {
//   return axios
//   .post(`${apiUri}/clinic/admin/${id}/resendInvitation`, request)
//   .then(response => response.data);
// };

export const validateRegistrationKey = (regKey: string) => {
  return axios.get(`${apiUri}/public/validateRegKey/${regKey}`)
  .then(response => response.data);
};

export const activateAccount = (account: Partial<UpsertAccountDto>, source: CancelTokenSource) => {
  return axios.post(`${apiUri}/public/activateAccount`, account, {cancelToken: source.token})
  .then(response => response.data);
};

export const resendInvitation = (accountId: number, request: Partial<TriggerAccountActivationRequest>) => {
  return axios.post(`${apiUri}/${accountId}/resendInvitation`, request)
  .then(response => response.data);
};

export const generateRegKey = (accountId: number, request: Partial<TriggerAccountActivationRequest>) => {
  return axios.post(`${apiUri}/${accountId}/generateRegKey`, request)
  .then(response => response.data);
};