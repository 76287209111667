import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import { ClinicDto } from 'ts-types/api.types';
import React, { useCallback, useState } from 'react';
import { getClinicData } from 'service/clinicService';

interface State {
  clinicData?: ClinicDto;
  setClinicData: (clinicDto: ClinicDto | undefined) => void;
}
//TODO check
const ClinicDataContext = React.createContext<State>({
  clinicData: undefined,
  setClinicData: (clinicDto: ClinicDto | undefined) => {},
});

const cancelTokenSource = axios.CancelToken.source();

interface Props extends JSX.ElementChildrenAttribute {}

const ClinicDataProvider = (props: Props) => {

  const {isAuthenticated, currentUser} = useAuthContext();

  const [clinicData, setClinicData] = useState<ClinicDto | undefined>(undefined);

  const fetchUserClinicData = useCallback(async () => {
    try {
      const clinic: ClinicDto = await getClinicData(cancelTokenSource);
      setClinicData(clinic);
    } catch (e) {}
  }, [clinicData, setClinicData]);

  React.useEffect(() => {

    const shouldGetClinicData = isAuthenticated
      && currentUser && !currentUser.roles.includes('ROLE_APP_ADMIN')
      && currentUser.clinicId;

    if (shouldGetClinicData) {
      fetchUserClinicData();
    }

  }, [isAuthenticated, currentUser]);


  const state: State = React.useMemo(() => {
    return { clinicData, setClinicData, fetchUserClinicData };
  }, [clinicData, setClinicData]);

  return (
    <ClinicDataContext.Provider value={state}>
      {props.children}
    </ClinicDataContext.Provider>

  );
};

export const useClinicDataContext = () => React.useContext(ClinicDataContext);

export default ClinicDataProvider;